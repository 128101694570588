import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
   const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedTheme = sessionStorage.getItem("isAuthenticated");
    return storedTheme ? JSON.parse(storedTheme) : false;
  });
  const navigate = useNavigate();

  const login = (email, password) => {
    if ((email == "admin@lionorbit.com" && password == "admin@123") || (email == "amoesis37@gmail.com" && password == "esic#345")) {
      setIsAuthenticated(true);
      navigate("/home");
    } else {
      alert("Incorrect credentials");
    }
  };
  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.clear();
    navigate("/");
  };
   useEffect(() => {
    sessionStorage.setItem("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
