import './App.css';
import Login from './Components/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './Components/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import { lazy, Suspense } from 'react';

const HomeNew = lazy(() => import("./Components/HomeNew"));
const PatientWiseDashboard = lazy(() => import("./Components/PatientWiseDashboard"));
const PrivacyPolicy = lazy(() => import("./Components/PrivacyPolicy"));

function App() {
  return (
    <div className="app">
      <Suspense fallback={<div>Loading ....</div>}>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/privacy" element={<PrivacyPolicy   />} />

              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <HomeNew />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/patientwisedashboard"
                element={
                  <ProtectedRoute>
                    <PatientWiseDashboard />
                  </ProtectedRoute>
                }
              />
               
            </Routes>
          </AuthProvider>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
