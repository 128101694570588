import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
   return isAuthenticated == true ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
